import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { ReactComponent as NoResultSvg } from 'assets/no-results.svg'

const NoStudyResultsPage = () => {
  return (
    <PageContainer className='p-2'>
      <StyledNoResultSvg />
      <Title>No results available yet</Title>
      <Subtitle>We are working on your results. Once they are ready you’ll be able to view them here!</Subtitle>
    </PageContainer>
  )
}

export default NoStudyResultsPage

const PageContainer = styled.div(
  (props) => `
    width: 100vw;
    height: 100vh;
    min-width: 320px;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15%;
    background-color: white;
  `,
)

const Title = styled.div(
  (props) => `
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #3A3A3A;
    margin-top: 30px;
    margin-bottom: 20px;
    line-height: 26px;
  `,
)

const Subtitle = styled.div(
  (props) => `
    font-size: 16px;
    line-height: 24px;
    color: #3A3A3A;
    text-align: center;
  `,
)

const StyledNoResultSvg = styled(NoResultSvg)(
  (props) => `
    width: 280px;
    height: 280px;
  `,
)
