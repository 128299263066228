import styled from '@emotion/styled'

import { H2 } from 'components/elements/Typography'

export default function HeaderCard(props) {
  let title = ''
  switch (props.type) {
    case 'sobriety':
      title = 'Sobriety and Well-Being Results'
      break
    case 'mhtrack':
      title = 'Mental Health Tracker Results'
      break
    case 'mdv1':
      title = 'Your Microdose.me v1 Results'
      break
  }
  return (
    <HeaderWrapper>
      <HeaderIMG alt='' src={require(`assets/${props.type}-results-header.png`)} />
      <PositionedH2>{title}</PositionedH2>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.div(
  (props) => `
    position: relative;
    min-height: 160px;
  `,
)

const PositionedH2 = styled(H2)(
  (props) => `
    font-family: 'Fraunces';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 150%;
    color: #3B434A;
    position: absolute;
    top: 40%;
    left: 6%;
    width: 55%;
    @media (max-width: 365px) {
      font-size: 17px;
    }
    @media (min-width: 365px) and (max-width: 400px) {
      font-size: 21px;
    }
  `,
)

const HeaderIMG = styled.img(
  (props) => `
    position: relative;
    margin: 0!important;
  `,
)
