import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import posthog from 'posthog-js'

import { useAppState, useActions, useEffects } from 'store'
import TextCard from 'components/cards/TextCard'
import ChartCard from 'components/cards/ChartCard'
import BetaBanner from 'components/elements/BetaBanner'
import BackNav from 'components/elements/BackNav'
import HeaderCard from 'components/cards/HeaderCard'
import FooterCard from 'components/cards/FooterCard'
import NoStudyResultsPage from 'pages/NoStudyResultsPage'
import StudyResultsErrorPage from 'pages/StudyResultsErrorPage'
import Spinner from 'components/elements/Spinner'
import { Container, SpinnerContainer, ResultsPage } from 'components/elements/Layout'

const options = {
  longitudinal: ['DASS-21', 'WHO Quality of Life', 'SWLS150', 'PANAS-10', 'FFMQ'],
}

declare global {
  interface Window {
    initWebView: any
    messageHandler: any
  }
}

const MHTrackResultsPage = () => {
  const [successGettingCharts, setSuccessGettingCharts] = useState<boolean | string | null>(null)
  const state = useAppState()
  const actions = useActions()
  const effects = useEffects()

  useEffect(() => {
    const chartNames = {}
    for (const chartType in options) {
      const chartsOfType = options[chartType]
      chartNames[chartType] = chartsOfType.map((chartName) => chartName.replaceAll(' ', '_').toLowerCase())
    }

    // Assign function to window that can be called from Flutter
    window.initWebView = function (payload) {
      posthog.identify(payload.participant_id)
      actions.setEnvName([payload.env_name])
      const amendedPayload = {
        chart_names: chartNames,
        ...payload,
        // Hard-coding study and ppt_id for testing
        // study_id: '5b4d96c3-677e-449a-ae28-e2e01a917042',
        // participant_id: 'ac65cc01-8f28-4817-bc0c-4b7fffc61ff0',
      }

      const sobrietyStudyData = state.studiesData.filter((studyData) => studyData['study_id'] === 'e3dd1acf-4ed8-4c12-aee8-6b4a69487202')
      if (sobrietyStudyData.length > 0) {
        amendedPayload.study_id = sobrietyStudyData[0]['study_id']
        amendedPayload.participant_id = sobrietyStudyData[0]['participant_id']
      }
      getSignedS3Url(amendedPayload)
    }

    // Notify Flutter that the function is ready to be called (to avoid race condition caused by useEffect)
    if (window?.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify({ message: 'function_ready' }))
    }
  }, [])

  const getSignedS3Url = async (payload) => {
    try {
      const [signedS3Urls, chartData] = await effects.getSignedS3Urls(payload)
      actions.setChartUrls([signedS3Urls, options])
      actions.setChartData([chartData, options])

      setSuccessGettingCharts(true)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: true }))
      }
    } catch (e: any) {
      setSuccessGettingCharts(false)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: false }))
      }
    }
  }

  if (successGettingCharts === false) {
    posthog.capture('mhtrack-showing-no-results')
    return <NoStudyResultsPage />
  }

  if (successGettingCharts === 'error') {
    return <StudyResultsErrorPage />
  }

  if (successGettingCharts === true) {
    posthog.capture('mhtrack-showing-charts')
    return (
      <Container>
        <BackNav />
        <BetaBanner />
        <ResultsPage className='p-2'>
          <HeaderCard type='mhtrack' />
          <TextCard type='welcome' />
          <ChartCard chartType='longitudinal' options={options.longitudinal} badgeLabel='Average score: ' badgeSuffix='%' studySlug='MHTRACK' />
          <FooterCard />
        </ResultsPage>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )
}

export default MHTrackResultsPage
