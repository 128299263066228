import styled from '@emotion/styled'

import Fader from 'components/elements/Fader'
import { H2, H4, P } from 'components/elements/Typography'

const getPad = (props) => {
  if (props.chart) return 'px-6 pt-3 pb-0'
  if (props.noBg) return 'px-2'
  if (props.noPad) return ''
  return 'p-5'
}

export default function Card(props) {
  return (
    <div className='w-full my-3'>
      <div className={`${props.noBg ? '' : 'bg-white shadow rounded-xl'} overflow-hidden`}>
        <div className={getPad(props)}>
          <Fader transitionKey={props.title}>{props.title && props.smallTitle ? <H2>{props.title}</H2> : <H2>{props.title}</H2>}</Fader>
          <Fader transitionKey={props.subtitle}>{props.subtitle && <H4>{props.subtitle}</H4>}</Fader>
          <P className='mt-2'>{props.children}</P>
        </div>
      </div>
    </div>
  )
}
