const titles = {
  SOBRIETY: {
    longitudinal: {
      titles: (chartName) => {
        if (chartName === 'All Sobriety') chartName = 'Combined'
        return `Your ${chartName} Scores`
      },
      accordionTitles: {
        'Sleep Quality': 'How does alcohol affect my sleep?',
        Activity: 'How does alcohol affect my activity level?',
        Concentration: 'How does alcohol affect my concentration?',
        Productivity: 'How does alcohol affect my productivity?',
      },
    },
    scatter: {
      titles: {
        'Sleep Quality': 'Sleep and Number of Drinks',
        Activity: 'Activity and Number of Drinks',
        Concentration: 'Concentration and Number of Drinks',
        Productivity: 'Productivity and Number of Drinks',
      },
      accordionTitles: {
        'Sleep Quality': 'How does alcohol affect my sleep?',
        Activity: 'How does alcohol affect my activity level?',
        Concentration: 'How does alcohol affect my concentration?',
        Productivity: 'How does alcohol affect my productivity?',
        'All Sobriety': null,
      },
    },
    bar: {
      titles: {
        AUDIT: 'Alcohol Use Disorders Identification Test (AUDIT-10)',
        'Sleep Quality': 'Sleep Evaluation Form',
        Wellbeing: 'World Health Organization Quality of Life',
      },
      subtitles: {
        AUDIT: 'General measure of alcohol consumption',
        'Sleep Quality': 'Custom measure of sleep quality',
        Wellbeing: "Assessments of an individual's perceptions of their health and well-being, across 6 main domains",
      },
      accordionTitles: 'How have my scores changed over a month?',
    },
  },
  MHTRACK: {
    longitudinal: {
      titles: (chartName) => `Your ${chartName} Scores`,
      accordionTitles: 'What do these scores mean?',
    },
  },
  MDV1: {
    drug_use: {
      titles: {
        'Common Drugs Usage': 'Your substance use compared to other participants',
        'Your Psychedelic Use Last Year': 'Your psychedelic use in the past year',
        'Your Psychedelic Use Lifetime': 'Your psychedelic use across your lifetime',
        'Others Psychedelic Use Last Year': "Other participants' psychedelic use in the past year",
        'Others Psychedelic Use Lifetime': "Other participants' psychedelic use across their lifetime",
      },
      subtitles: (chartName) => {
        switch (chartName) {
          case 'Others Psychedelic Use Lifetime':
            return 'Aggregated usage frequencies for psychedelics across entire lifetime'
          case 'Others Psychedelic Use Last Year':
            return 'Aggregated usage frequencies for psychedelics last year'
          default:
            return 'Usage frequencies per substance'
        }
      },
      accordionTitles: 'What do these scores mean?',
    },
    longitudinal: {
      titles: 'Your daily functioning and microdosing over time',
      accordionTitles: (chartName) => `How does microdosing affect your ${chartName.toLowerCase()}?`,
    },
    scatter: {
      titles: 'Correlation between microdosing and daily ratings',
      accordionTitles: (chartName) => `How does microdosing affect your ${chartName.toLowerCase()}?`,
    },
    bar: {
      titles: 'Monthly Insights',
      subtitles: {
        'DASS-21':
          'The Depression, Anxiety, and Stress Scale (DASS-21) is a set of three self-report scales designed to measure the emotional states of depression, anxiety and stress.',
        'PANAS-10':
          'The Positive and Negative Affect Schedule (PANAS) is a self-report questionnaire that consists of two 10-item scales, to measure positive and negative affect.',
        'Finger Tapping': 'A standard measure of psychomotor processing speed.',
      },
      accordionTitles: (chartName) => `How have your ${chartName} Scores changed over time?`,
    },
  },
}

export default titles
