import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftIcon } from '@heroicons/react/solid'

export default function BackNav(props) {
  const navigate = useNavigate()

  return (
    <Container onClick={() => navigate('/results')}>
      <IconContainer>
        <ArrowLeftIcon />
      </IconContainer>
      <Title>Back to results</Title>
    </Container>
  )
}

const Container = styled.div(
  (props) => `
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
  `,
)

const IconContainer = styled.div(
  (props) => `
    width: 25px;
    height: 25px;
    margin-left: 10px;
  `,
)

const Title = styled.div(
  (props) => `
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin-left: 5px;
    font-family: 'Open Sans'
  `,
)
