export const getSignedS3Urls = async (body) => {
  const rawResponse = await fetch('https://cn5btyqyjdamv7hcfcknuzjq3i0gviww.lambda-url.ca-central-1.on.aws/', {
    method: 'POST',
    body: JSON.stringify(body),
  })
  const content = await rawResponse.json()

  if (content.status_code === 200) {
    return [content.urls, content.data]
  } else {
    console.error('===========  LAMBDA_ERROR  ===========')
    console.error("'get_presigned_url' Lambda function returned status code:", content.status_code)
    content.error.split('\n').forEach((line) => console.error(line))
    console.error('=========== /LAMBDA_ERROR  ===========')

    throw new Error('Request to sign S3 URL failed.')
  }
}
