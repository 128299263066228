import styled from '@emotion/styled'

export const H1 = styled.h2(
  (props) => `
        font-family: 'Fraunces';
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 125%;
        color: #212427;
        margin-bottom: 15px;
      `,
)

export const H2 = styled.h2(
  (props) => `
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 125%;
      color: #3B434A;
      margin: 10px 0;
    `,
)

export const H3 = styled.h3(
  (props) => `
      font-family: 'Fraunces';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 125%;
      color: #3B434A;
      margin: 10px 0;
    `,
)

export const H4 = styled.h4(
  (props) => `
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 16px;
      line-height: 125%;
      font-weight: 400;
      color: #777;
      margin: 10px 0;
    `,
)

export const H3Sans = styled.div(
  (props) => `
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: #007D55;
`,
)

export const P = styled.div(
  (props) => `
      color: #3B434A;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    `,
)
