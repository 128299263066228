import ReactCSSTransitionReplace from 'react-css-transition-replace'

const transitionLength = 500

export default function Fader(props) {
  return (
    // <ReactCSSTransitionReplace transitionName='fade-wait' transitionEnterTimeout={transitionLength} transitionLeaveTimeout={transitionLength}>
    <div key={props.transitionKey}>{props.children}</div>
    // </ReactCSSTransitionReplace>
  )
}
