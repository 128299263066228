// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCPqEijGsYYr5MHq5juflyKau9emN7GMzs',
  authDomain: 'qc-results-demo.firebaseapp.com',
  projectId: 'qc-results-demo',
  storageBucket: 'qc-results-demo.appspot.com',
  messagingSenderId: '383123699083',
  appId: '1:383123699083:web:db12839db1941c7b392b8e',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
