import styled from '@emotion/styled'

export const Button = styled.button(
  (props) => `
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 9px 21px;
      width: 100%;
      height: 42px;
      background: #3DDD9E;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 21px;
      flex: none;
      order: 3;
      flex-grow: 0;
    `,
)
