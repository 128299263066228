/* This example requires Tailwind CSS v2.0+ */
import styled from '@emotion/styled'

import { Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const Select = (props) => {
  useEffect(() => {
    if (!props.selected?.name) {
      props.setSelected(props.options[0])
    }
  }, [])

  return (
    <Listbox value={props.selected} onChange={props.setSelected}>
      {({ open }) => (
        <>
          <div className='mt-1 relative'>
            <Listbox.Button className='relative bg-white rounded-md pl-3 pr-10 py-2 text-left cursor-default sm:text-sm'>
              <Selected className='block truncate'>{props.selected?.name}</Selected>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronDownIcon className='h-5 w-5 text-gray-600' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
              <Listbox.Options className='absolute z-10 mt-1 bg-white shadow-lg max-h-80 rounded-md py-1 pl-0 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                {props.options.map((option) => (
                  <Listbox.Option
                    key={option}
                    className={({ active }) =>
                      classNames(active ? 'text-white bg-primary' : 'text-gray-900', 'cursor-default select-none relative py-2 px-5 list-none border-b')
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option}</span>

                        {selected ? (
                          <span className={classNames(active ? 'text-white' : 'text-primary', 'absolute inset-y-0 left-0 flex items-center')}>
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default Select

const Selected = styled.div(
  (props) => `
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #212427;
  `,
)
