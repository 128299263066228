import styled from '@emotion/styled'

import { useAppState, useActions } from 'store'
import Card from 'components/elements/Card'

export default function TextCard(props) {
  const state = useAppState()
  const actions = useActions()

  let title, body, smallTitle
  switch (props.type) {
    case 'welcome':
      title = null
      body =
        'Below you will find your personalized insights that you can use to inform your future health related choices. We believe this information can be empowering.'
      break
    case 'sobriety-scatter':
      smallTitle = true
      title = "Let's explore the relationships between the amount of alcohol you consumed and your daily reports."
      body =
        'Compare the number of drinks you consumed to each measured element of well-being. Does your score go up or down with the number of drinks? Or is the line flat indicating no relationship between these two factors?'
      break
    case 'sobriety-bar':
      smallTitle = true
      title = 'Let’s explore the monthly changes in scores.'
      body = 'Compare your baseline scores (at the beginning of the study) with scores 1 month after joining the study. Do you see any differences?'
      break
    case 'mdv1-welcome':
      title = null
      body =
        'Below you will find your personalized insights. Take note of any patterns, links between scores, and changes over time. We hope this report empowers you in making health choices.'
      break
    case 'mdv1-drug-use':
      title = null
      body = 'mdv1-drug-use placeholder'
      break
    case 'mdv1-longitudinal':
      title = 'Insights from the Daily Survey'
      body =
        'Let’s, first, explore changes in your well-being and functioning during the study. If you microdosed, your microdosing practice and stacking is encoded on the plot. Do you see interesting patterns?'
      break
    case 'mdv1-scatter':
      title = 'Microdosing and Daily Ratings'
      body =
        'Compare your number of microdosing days to your scores on daily well-being related ratings. Does your score go up or down with the increase of microdosing days? If the line is flat, this indicates no relationship between these two factors. '
      break
    case 'mdv1-bar':
      title = 'Monthly Insights'
      body = 'Compare your baseline scores (at the beginning of the study) with scores from months 1, 2, and 3. Do you see any differences?'
      break
    default:
      return null
  }

  return (
    <Card noBg noPad smallTitle={smallTitle} title={title}>
      {body}
    </Card>
  )
}
