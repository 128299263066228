import styled from '@emotion/styled'

import Card from 'components/elements/Card'
import RadialProgress from 'components/elements/RadialProgress'
import { H3, P } from 'components/elements/Typography'

const Emoji = (props) => {
  if (!props.symbol) return null
  return (
    <span style={{ marginLeft: '5px' }} role='img'>
      {String.fromCodePoint(props.symbol)}
    </span>
  )
}

export default function ProgressCard(props) {
  let title = ''
  let symbol = ''
  let note = ''
  if (props.value >= 90) {
    title = 'You nailed it!'
    symbol = '0x1F929'
  } else if (70 <= props.value && props.value < 90) {
    title = 'Nice job!'
    symbol = '0x1F44D'
  } else if (50 <= props.value && props.value < 69) {
    title = 'Keep it up!'
    symbol = '0x1F642'
  } else {
    note = 'Try to complete more scales in the future to unlock more figures!'
  }
  return (
    <Card title='My Progress'>
      <Row>
        <ProgressContainer>
          <RadialProgress value={props.value} />
        </ProgressContainer>
        <Column>
          {title && (
            <H3>
              {title}
              <Emoji symbol={symbol} />
            </H3>
          )}
          <P>
            You have completed <b>{props.value}%</b> of all tasks in this study.
          </P>
          {note && <P>{note}</P>}
        </Column>
      </Row>
    </Card>
  )
}

const Row = styled.div(
  (props) => `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-top: 18px;
  `,
)

const Column = styled.div(
  (props) => `
    padding-left: 25px;
    margin-bottom: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    & > h2 {
      margin-bottom: 3px;
    }
    & > p {
      margin: 0;
    }
  `,
)

const ProgressContainer = styled.div(
  (props) => `
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)
