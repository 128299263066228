const TestPage = () => {
  return (
    <div>
      <button
        onClick={() => {
          throw Error('testerror')
        }}
      >
        Break the app
      </button>
    </div>
  )
}

export default TestPage
