import styled from '@emotion/styled'

import Card from 'components/elements/Card'
import RadialProgress from 'components/elements/RadialProgress'

export default function BetaCard(props) {
  return (
    <Container>
      <Title>You are in beta!</Title>
      <Body>
        If you notice something isn't working, please <a href='https://survey.hsforms.com/1onJh_3R-SD2BykB99dTYbgca2xk'>let us know</a>!
      </Body>
    </Container>
  )
}

const Container = styled.div(
  (props) => `
    width: 100%;
    background: #EFF3FE;
    display: flex;
    flex-direction: column;
    padding: 15px;
  `,
)

const Title = styled.div(
  (props) => `
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  `,
)

const Body = styled.div(
  (props) => `
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #3A3A3A;
  `,
)
