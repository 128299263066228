import styled from '@emotion/styled'

export const ResultsPage = styled.div(
  (props) => `
    width: 100vw;
    min-width: 320px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
  `,
)

export const Container = styled.div(
  (props) => `
      display: flex;
      flex-direction: column;
    `,
)

export const SpinnerContainer = styled.div(
  (props) => `
      display: flex;
      align-itemes: center;
      justify-content: center;
      height: 100vh;
    `,
)
