import { useState, useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = useState(valueStart)
  useEffect(() => {
    setValue(valueEnd)
  }, [valueEnd])

  return children(value)
}

export default function RadialProgress(props) {
  const percentage = props?.value || '0'
  const styles = buildStyles({
    pathColor: '#3DDD9E',
    textColor: '#3DDD9E',
  })
  // @ts-ignore
  styles.text = {
    fontWeight: 700,
    fontFamily: 'Fraunces',
    fontSize: '24px',
    fill: '#333',
  }

  return (
    <ProgressProvider valueStart={0} valueEnd={percentage}>
      {(value) => <CircularProgressbar value={value} text={`${percentage}%`} styles={styles} />}
    </ProgressProvider>
  )
}
