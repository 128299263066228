import styled from '@emotion/styled'

export const Divider = styled.div(
  (props) => `
        width: 100%;
        height: 0px;
        border: 1px solid #3DDD9E;
        margin: 20px 0px;
    `,
)
