import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import posthog from 'posthog-js'

import { useAppState, useActions, useEffects } from 'store'
import ProgressCard from 'components/cards/ProgressCard'
import TextCard from 'components/cards/TextCard'
import ChartCard from 'components/cards/ChartCard'
import BetaBanner from 'components/elements/BetaBanner'
import BackNav from 'components/elements/BackNav'
import ScoreCard from 'components/cards/ScoreCard'
import HeaderCard from 'components/cards/HeaderCard'
import FooterCard from 'components/cards/FooterCard'
import NoStudyResultsPage from 'pages/NoStudyResultsPage'
import StudyResultsErrorPage from 'pages/StudyResultsErrorPage'
import Spinner from 'components/elements/Spinner'
import { Container, SpinnerContainer, ResultsPage } from 'components/elements/Layout'

const options = {
  longitudinal: ['Activity', 'Productivity', 'Sleep Quality', 'Concentration', 'All Sobriety'],
  scatter: ['Sleep Quality', 'Activity', 'Concentration', 'Productivity'],
  bar: ['AUDIT', 'Sleep Quality', 'Wellbeing'],
}

declare global {
  interface Window {
    initWebView: any
    messageHandler: any
  }
}

const SobrietyResultsPage = () => {
  const [successGettingCharts, setSuccessGettingCharts] = useState<boolean | string | null>(null)
  const state = useAppState()
  const actions = useActions()
  const effects = useEffects()

  useEffect(() => {
    const chartNames = {}
    for (const chartType in options) {
      const chartsOfType = options[chartType]
      chartNames[chartType] = chartsOfType.map((chartName) => chartName.replaceAll(' ', '_').toLowerCase())
    }

    // Only for web
    // getSignedS3Url({
    //   chart_names: chartNames,
    //   qc_username: '8Rh3y2AnHQHloA1akg7y8alG7KWh9ngS+cD56sfBlec=@v2.quantifiedcitizen.com',
    //   qc_session_token: 'XpyJG7Y10msgjYoKiDoNdMriUa31Sw5Eo2maLDLlBJey1L5O6COOABr2eF7L6s8A',
    //   qc_authorization:
    //     'Basic WHB5Skc3WTEwbXNnallvS2lEb05kTXJpVWEzMVN3NUVvMm1hTERMbEJKZXkxTDVPNkNPT0FCcjJlRjdMNnM4QTpiMDRlYmJlODdmMWY5ZmNjYTE5YzhhNGE5NTNjZDY2ODE4ZDRlODA2',
    //   study_id: '5b4d96c3-677e-449a-ae28-e2e01a917042',
    //   participant_id: '1d551992-7c58-4199-9b59-6ff5f188fa36',
    // })

    // Assign function to window that can be called from Flutter
    window.initWebView = function (payload) {
      posthog.identify(payload.participant_id)
      actions.setEnvName([payload.env_name])
      const amendedPayload = {
        chart_names: chartNames,
        ...payload,
        // Hard-coding study and ppt_id for testing
        // study_id: '5b4d96c3-677e-449a-ae28-e2e01a917042',
        // participant_id: 'ac65cc01-8f28-4817-bc0c-4b7fffc61ff0',
      }

      const sobrietyStudyData = state.studiesData.filter((studyData) => studyData['study_id'] === '5b4d96c3-677e-449a-ae28-e2e01a917042')
      if (sobrietyStudyData.length > 0) {
        amendedPayload.study_id = sobrietyStudyData[0]['study_id']
        amendedPayload.participant_id = sobrietyStudyData[0]['participant_id']
      }
      getSignedS3Url(amendedPayload)
    }

    // Notify Flutter that the function is ready to be called (to avoid race condition caused by useEffect)
    if (window?.messageHandler) {
      window.messageHandler.postMessage(JSON.stringify({ message: 'function_ready' }))
    }
  }, [])

  const getSignedS3Url = async (payload) => {
    try {
      const [signedS3Urls, chartData] = await effects.getSignedS3Urls(payload)
      actions.setChartUrls([signedS3Urls, options])
      actions.setChartData([chartData, options])

      setSuccessGettingCharts(true)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: true }))
      }
    } catch (e: any) {
      setSuccessGettingCharts(false)
      if (window?.messageHandler) {
        window.messageHandler.postMessage(JSON.stringify({ success: false }))
      }
    }
  }

  if (successGettingCharts === false) {
    posthog.capture('sobriety-showing-no-results')
    return <NoStudyResultsPage />
  }

  if (successGettingCharts === 'error') {
    return <StudyResultsErrorPage />
  }

  if (successGettingCharts === true) {
    posthog.capture('sobriety-showing-charts')
    return (
      <Container>
        <BackNav />
        <BetaBanner />
        <ResultsPage className='p-2'>
          <HeaderCard type='sobriety' />
          <TextCard type='welcome' />
          <ChartCard chartType='longitudinal' options={options.longitudinal} badgeLabel='Average score: ' badgeSuffix='%' studySlug='SOBRIETY' />
          <TextCard type='sobriety-scatter' />
          <ChartCard chartType='scatter' options={options.scatter} badgeLabel='Correlation: ' studySlug='SOBRIETY' />
          <TextCard type='sobriety-bar' />
          <ChartCard chartType='bar' options={options.bar} showInlineLegend studySlug='SOBRIETY' />
          <FooterCard />
        </ResultsPage>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  )
}

export default SobrietyResultsPage
