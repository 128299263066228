import styled from '@emotion/styled'
import ReactCSSTransitionReplace from 'react-css-transition-replace'

import Spinner from 'components/elements/Spinner'

const transitionLength = 500

const Content = (props) => {
  const Chart = props.component

  const StyledChart = styled(Chart)(
    (props) => `
      height: ${props.height ? props.height : '100%'};
      background-color: white;
      margin-right: auto;
    `,
  )

  if (!props.src) {
    return <Spinner />
  }

  if (props.src === 'no-chart') {
    return (
      <Placeholder>
        <Title>Not enough data to display your results</Title>
        <Subtitle>Please check back later!</Subtitle>
      </Placeholder>
    )
  }

  return <StyledChart src={props.src} height={props.height} preserveAspectRatio='xMinYMin' />
}

export default function ChartFader(props) {
  return (
    // <ReactCSSTransitionReplace transitionName='fade-wait' transitionEnterTimeout={transitionLength} transitionLeaveTimeout={transitionLength}>
    <Container key={props.src} height={props.height}>
      <Content {...props} />
    </Container>
    // </ReactCSSTransitionReplace>
  )
}

interface ContainerProps {
  height: number
}

const Container = styled.div<ContainerProps>(
  (props) => `
    height: ${props.height};
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3DDD9E;
  `,
)

const Placeholder = styled.div(
  (props) => `
    height: 290px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
)

const Title = styled.div(
  (props) => `
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #3A3A3A;
    margin-bottom: 10px;
    text-align: center;
  `,
)

const Subtitle = styled.div(
  (props) => `
    font-size: 13px;
    line-height: 150%;
    color: #3A3A3A;
    text-align: center;
  `,
)
