import sobrietyAccordionBody from './sobriety'
import mhtrackAccordionBody from './mhtrack'
import mdv1AccordionBody from './mdv1'

const getAccordionBody = (chartType) => {
  switch (chartType) {
    case 'SOBRIETY':
      return sobrietyAccordionBody
    case 'MHTRACK':
      return mhtrackAccordionBody
    case 'MDV1':
      return mdv1AccordionBody
    default:
      return null
  }
}

export default getAccordionBody
