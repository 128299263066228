import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { ReactComponent as NoResultSvg } from 'assets/no-results.svg'

const StudyResultsErrorPage = () => {
  return (
    <PageContainer className='p-2'>
      <StyledNoResultSvg />
      <Title>There was an error while getting the study results.</Title>
      <Subtitle>Please try again!</Subtitle>
    </PageContainer>
  )
}

export default StudyResultsErrorPage

const PageContainer = styled.div(
  (props) => `
    width: 100vw;
    height: 50vh;
    min-width: 320px;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 15%;
  `,
)

const Title = styled.div(
  (props) => `
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: #444;
    margin: 30px 0;
    line-height: 26px;
  `,
)

const Subtitle = styled.div(
  (props) => `
    font-size: 15px;
  `,
)

const StyledNoResultSvg = styled(NoResultSvg)(
  (props) => `
    width: 40%;
    height: auto;
  `,
)
