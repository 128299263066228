import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import posthog from 'posthog-js'

import './index.css'
import Main from './Main'
import reportWebVitals from './reportWebVitals'
import { createOvermind } from 'overmind'
import { Provider } from 'overmind-react'
import { config } from './store'

posthog.init('phc_gmNZIcKeLhJlrzRUwQD5G9qAM81qvZmsei0c1c7Xexs', { api_host: 'https://posthog.quantifiedcitizen.com' })
posthog.register({ appName: 'qc-results-frame' })

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  // production code
  Sentry.init({
    dsn: 'https://8db57950447e4cfbaab4c50faf8e0a7a@sentry.quantifiedcitizen.com/8',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const overmind = createOvermind(config)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider value={overmind}>
    <Main />
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
