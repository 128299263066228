import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { firebaseApp } from 'firebaseConfig'
import titles from 'components/content/titles'

const getChartDetailByType = (chartDetail, chartName) => {
  switch (typeof chartDetail) {
    case 'string':
      return chartDetail
    case 'function':
      return chartDetail(chartName)
    case 'object':
      return chartDetail[chartName]
    default:
      return ''
  }
}

export const setSelectedChart = ({ state }, [studySlug, chartType, newSelectedChartName]) => {
  const chartName = newSelectedChartName.replaceAll(' ', '_').toLowerCase()
  state.activeCharts[chartType] = {
    name: newSelectedChartName,
    title: getChartDetailByType(titles?.[studySlug]?.[chartType]?.titles, newSelectedChartName),
    subtitle: getChartDetailByType(titles?.[studySlug]?.[chartType]?.subtitles, newSelectedChartName),
    accordionTitle: getChartDetailByType(titles?.[studySlug]?.[chartType]?.accordionTitles, newSelectedChartName),
    url: state.chartUrls?.[chartType]?.[chartName],
    badgeValue: state.chartData?.[chartType]?.[chartName]?.chart_badge_value,
  }
}

export const login = async ({ state }, [email, password]) => {
  try {
    const auth = getAuth(firebaseApp)
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    state.signedInUserEmail = userCredential.user.email
  } catch (e) {
    console.error(e)
    window.alert('Login failed')
  }
}

export const setChartUrls = async ({ state }, [chartUrls, options]) => {
  state.chartUrls = chartUrls

  // Initialize URL so it displays on load correctly
  for (const chartType in state.activeCharts) {
    const firstOption = options[chartType][0].replaceAll(' ', '_').toLowerCase()
    state.activeCharts[chartType].url = chartUrls[chartType][firstOption]
  }
}

export const setChartData = async ({ state }, [chartData, options]) => {
  state.chartData = chartData
  // Initialize badgeValue so it displays on load correctly
  for (const chartType in state.activeCharts) {
    const firstOption = options[chartType][0].replaceAll(' ', '_').toLowerCase()
    state.activeCharts[chartType].badgeValue = state.chartData?.[chartType]?.[firstOption]?.chart_badge_value
  }
}

export const setStudiesData = async ({ state }, [studiesData]) => {
  state.studiesData = studiesData
}

export const setEnvName = async ({ state }, [envName]) => {
  if (['services', 'api', 'production'].includes(envName)) {
    state.isProduction = true
  } else {
    state.isProduction = false
  }
}
