import styled from '@emotion/styled'

const sobrietyAccordionContent = (props) => {
  switch (props.chartType) {
    case 'longitudinal':
      let phrase1Longitudinal
      switch (props.chartName) {
        case 'Sleep Quality':
          phrase1Longitudinal = 'sleep quality'
          break
        case 'Activity':
          phrase1Longitudinal = 'exercise'
          break
        case 'Concentration':
          phrase1Longitudinal = 'concentration'
          break
        case 'Productivity':
          phrase1Longitudinal = 'productivity'
          break
        default:
          throw Error('Chart type not included in longitudinal info component in sobriety study.')
      }

      return (
        <div>
          This is your {phrase1Longitudinal} score <strong>over time</strong> based on the <i>Daily Alcohol Consumption Questionnaire.</i> A higher score means
          better {phrase1Longitudinal}.
        </div>
      )
    case 'scatter':
      let phrase1Scatter
      switch (props.chartName) {
        case 'Sleep Quality':
          phrase1Scatter = 'sleep quality'
          break
        case 'Activity':
          phrase1Scatter = 'exercise'
          break
        case 'Concentration':
          phrase1Scatter = 'concentration'
          break
        case 'Productivity':
          phrase1Scatter = 'productivity'
          break
        default:
          throw Error('Chart type not included in longitudinal info component in sobriety study.')
      }

      return (
        <div>
          <StyledUl>
            <li>
              Check out how drinking alcohol <b>relates</b> to your {phrase1Scatter}. Is your {phrase1Scatter} score better or worse when you drink more?
            </li>
            <li>
              Each dot represents a data entry. The green <b>regression line</b> represents the general association between number of drinks and{' '}
              {phrase1Scatter}.
            </li>
            <li>
              The <b>correlation</b> value between the number of drinks and {phrase1Scatter} score is shown in the top right. A <b>positive correlation</b>{' '}
              (maximum +1.0) means the two measures move in the same direction: when one moves up, the other will as well. A <b>negative correlation</b>{' '}
              (minimum -1.0) indicates that they move in opposite directions, if the value is zero, there is no relationship between the measures.
            </li>
            <li>
              The green shaded area around the regression line is called a 95% <b>confidence interval</b>. This indicates that at least 95% of your data entries
              will be within this range.
            </li>
          </StyledUl>
        </div>
      )
    case 'bar':
      let phrase1Bar
      switch (props.chartName) {
        case 'AUDIT':
          phrase1Bar = 'AUDIT-10  (alcohol consumption survey)'
          break
        case 'Sleep Quality':
          phrase1Bar = 'Sleep Evaluation Form '
          break
        case 'Wellbeing':
          break
        default:
          throw Error('Chart type not included in longitudinal info component in sobriety study.')
      }

      if (props.chartName === 'Wellbeing') {
        return (
          <div>
            Here are your scores on the well-being scale at the beginning of the study (baseline) and 1 month later. How were the various areas of your
            well-being affected over a month of paying attention to your sobriety? The dotted line represents the score in the general population. How do you
            compare?
          </div>
        )
      }

      return (
        <div>
          Here are your scores on the {phrase1Bar} at the beginning of the study (baseline) and after 1 month. How did your scores change over a month of paying
          attention to your sobriety?
        </div>
      )
  }
}

export default sobrietyAccordionContent

const StyledUl = styled.ul(
  (props) => `
    padding-left: 12px;
    `,
)
