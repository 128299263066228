import React from 'react'
import { App } from 'konsta/react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import styled from '@emotion/styled'
import 'tw-elements'

import LoginPage from 'pages/LoginPage'
import SobrietyResultsPage from 'pages/SobrietyResultsPage'
import MHTrackResultsPage from 'pages/MHTrackResultsPage'
import MDv1ResultsPage from 'pages/MDv1ResultsPage'
import NoStudyResultsPage from 'pages/NoStudyResultsPage'
import ResultsPage from 'pages/ResultsPage'
import TestPage from 'pages/TestPage'

import PrivateRoute from 'router/PrivateRoute'

function Main() {
  return (
    <>
      <StyledApp theme='material' dark={false} className='prose'>
        <BrowserRouter>
          <Routes>
            <Route path='/testpage' element={<TestPage />} />
            <Route path='/5b4d96c3-677e-449a-ae28-e2e01a917042' element={<SobrietyResultsPage />} /> {/* Sobriety - Production */}
            <Route path='/b45f7009-e8f4-45f5-b109-8958ea9a15c4' element={<SobrietyResultsPage />} /> {/* Sobriety - Integration */}
            <Route path='/e3dd1acf-4ed8-4c12-aee8-6b4a69487202' element={<MHTrackResultsPage />} /> {/* MHTrack - Production */}
            <Route path='/1cfe8639-5d76-4504-a7c4-34884195de69' element={<MDv1ResultsPage />} /> {/* MDv1 - Production */}
            <Route path='/results' element={<ResultsPage />} />
            <Route path='*' element={<NoStudyResultsPage />} />
            {/* <Route path='/' element={<LoginPage />} /> */}
            {/* <Route path='/demo' element={<PrivateRoute component={SobrietyResultsPage} />} /> */}
          </Routes>
        </BrowserRouter>
      </StyledApp>
    </>
  )
}

export default Main

const StyledApp = styled(App)(
  (props) => `
    display: flex;
    align-items: center;
    justify-content: center;
  `,
)
